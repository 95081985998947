<template>
  <section class="CreateAndUpdateProvider_root">
    <AppSection title="Add Route">
      <div slot="tollbar">
        <RoundButton class="CreateAndUpdateProvider_backBtn" withIcon iconType="arrowLeft" text="back to dashboard" @click="goBack" />
      </div>
      <div class="CreateAndUpdateProvider_wrapper" slot="content" v-if="$route.params.providerId">
        <v-form class="CreateAndUpdateProvider_Form" ref="form" v-if="provider">
          <div class="CreateAndUpdateProvider_title">Add Payment route for ({{ provider.name }}: {{ provider.provider }})</div>
          <TextFieldInput
            requireStar
            v-model="name"
            name="name"
            placeholder="Name"
            :rules="[...inputRules.required]"
            label="Name Route"
          />
          <div class="CreateAndUpdateProvider_OperationType">
            <div class="CreateAndUpdateProvider_OperationTypeTitle">Operation type</div>
            <div class="CreateAndUpdateProvider_OperationTypeItems">
              <label class="CreateAndUpdateProvider_OperationTypeItem">
                <input type="radio" name="operation-type" value="DEPOSIT" v-model="operationType" />
                <div class="InputRadioCirle"></div>
                <div class="CreateAndUpdateProvider_OperationTypeItemTitle">Deposit</div>
              </label>
              <label class="CreateAndUpdateProvider_OperationTypeItem">
                <input type="radio" name="operation-type" value="WITHDRAWAL" v-model="operationType" />
                <div class="InputRadioCirle"></div>
                <div class="CreateAndUpdateProvider_OperationTypeItemTitle">Withdraw</div>
              </label>
            </div>
          </div>
          <CurrenciesList
            ref="CurrenciesList"
            v-if="provider.currencies"
            :availableCurrency="provider.currencies"
            :selectedCurrency="selectedCurrency"
            :required="true"
            @changeSelectedCurrency="changeSelectedCurrency"
          />
          <div class="CreateAndUpdateProvider_FormBody">
            <Select
              class="CreateAndUpdateProvider_select"
              :items="paymentMethods"
              :value="paymentMethod"
              name="payment_methods"
              label="Payment Method"
              @change="changePaymentMethod"
              componentName="VAutocomplete"
              :rules="[...inputRules.required]"
              :hide-details="false"
              :requireStar="true"
            />
            <Select
              class="CreateAndUpdateProvider_select"
              :items="payCurrencies"
              :value="payCurrency"
              name="pay_currency"
              label="Pay Currency"
              @change="changePayCurrency"
              componentName="VAutocomplete"
              :rules="[...inputRules.required]"
              :hide-details="false"
              no-data-text="Please select Payment Method"
              requireStar
              :disabled="payCurrencies.length < 2"
            />
          </div>
          <div class="CreateAndUpdateProvider_title">Route fee</div>
          <div class="CreateAndUpdateProvider_FormBody" v-if="fieldsRouteFee.length">
            <TextFieldInput
              v-for="field in fieldsRouteFee"
              :key="field.name"
              :value="field.value"
              :name="field.name"
              placeholder=""
              :rules="field.is_required ? [...inputRules.required] : []"
              :requireStar="field.is_required"
              :label="field.description"
              @input="field.value = `${arguments[0]}`"
              type="Number"
            />
          </div>
          <div class="CreateAndUpdateProvider_title">Route limits</div>
          <div class="CreateAndUpdateProvider_FormBody" v-if="fieldsRouteLimits.length">
            <TextFieldInput
              v-for="field in fieldsRouteLimits"
              :key="field.name"
              :value="field.value"
              :name="field.name"
              placeholder=""
              :rules="field.is_required ? [...inputRules.required] : []"
              :requireStar="field.is_required"
              :label="field.description"
              @input="field.value = `${arguments[0]}`"
              type="Number"
            />
          </div>
          <div class="flex gap-10 md:gap-20">
            <Button
              class="btn-default-large btn-full-width"
              text="cancel"
              size="large"
              @click="goBack"
              hoverIconColor="#2d4d70"
              fillIconColor="#2af3f3"
            >
              <template v-slot:icon="{ fillIconColor }">
                <iconArrowLeft :fillColor="fillIconColor" />
              </template>
            </Button>
            <Button
              class="btn-full-width btn-default-large btn-default-green"
              text="SAVE"
              size="large"
              @click="handleSubmit"
            >
              <template #icon>
                <iconVerified
                  :height="12"
                  :width="12"
                />
              </template>
            </Button>
            <!-- <Button
              class="btn-default-large btn-full-width btn-default-red ml-[7px]"
              text="delete"
              size="large"
              @click="handleDeleteProvider"
            >
              <template #icon>
                <iconDelete fillColor="#ff556d" />
              </template>
            </Button> -->
          </div>
        </v-form>
      </div>
    </AppSection>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import RoundButton from '@/components/_common/FormElements/RoundButton/RoundButton';
import AppSection from '@/components/_common/AppSection/AppSection';
import TextFieldInput from '@/components/_common/FormElements/TextFieldInput/TextFieldInput';
import Select from '@/components/_common/FormElements/Select/Select';
import Button from '@/components/_common/FormElements/Button/Button';
import inputRules from '@/utils/inputRules';
import { validateForm } from '@/mixins/validateForm';
import CurrenciesList from './CurrenciesList/CurrenciesList';
import { arrowLeft as iconArrowLeft } from '@/assets/icons/arrows/';
import { iconDelete, iconVerified } from '@/assets/icons/common/';
import ProviderInfo from './ProviderInfo/ProviderInfo';
import { isEmpty } from 'ramda';
import { showAppNotification } from '@/utils/appNotification';

export default {
  components: {
    AppSection,
    Button,
    Select,
    RoundButton,
    TextFieldInput,
    CurrenciesList,
    iconDelete,
    iconArrowLeft,
    iconVerified,
    ProviderInfo,
  },
  mixins: [validateForm],
  data: () => ({
    inputRules,
    providerData: {},
    router: null,
    name: '',
    payCurrency: '',
    paymentMethod: '',
    operationType: 'DEPOSIT',
    fieldsRouteFee: [
      { 
        name: 'static_fee',
        is_required: false, 
        description: 'Static fee',
        value: '',
      },
      { 
        name: 'percent_fee', 
        is_required: false, 
        description: 'Percent fee',
        value: '',
      },
      { 
        name: 'client_static_fee', 
        is_required: false, 
        description: 'Client static fee',
        value: '',
      },
      { 
        name: 'client_percent_fee', 
        is_required: false, 
        description: 'Client percent fee',
        value: '',
      },
    ],
    fieldsRouteLimits: [
      { 
        name: 'min_amount', 
        is_required: false, 
        description: 'min amount',
        value: '',
      },
      { 
        name: 'max_amount', 
        is_required: false, 
        description: 'max amount',
        value: '',
      },
    ],
    selectedCurrency: [],
  }),
  watch: {
    provider: {
      handler(value) {
        if (this.provider) {
          this.apiGetFieldsArrayBySelectedProvider(this.provider.provider).then(data => {
            this.providerData = data;
          });
          if (this.$route.params.routeId && this.provider) { 
            this.router = this.provider.payment_routes.find(route=>route.id==this.$route.params.routeId)
            this.setRouterFields();
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('providers', ['supportedProviderIntegrations', 'activateProviderForm', 'userProviders']),
    provider() {
      let provider = null;
      for (let index in this.userProviders) {
        if (this.userProviders[index].id == this.$route.params.providerId) {
          provider = this.userProviders[index];
        }
      }
      return provider;
    },
    payCurrencies() {
      return this.provider.payment_methods?.[this.paymentMethod] || [];
    },
    paymentMethods() {
      let payment_methods = [];
      for (const payment_method in this.provider.payment_methods) {
        payment_methods.push(payment_method);
      }
      return payment_methods;
    },
  },
  methods: {
    ...mapActions('providers', [
      'apiGetFieldsArrayBySelectedProvider', 
      'apiCreateRoute',
      'apiUpdateRoute',
      'apiGetUserProviders',
    ]),
    changePaymentMethod(paymentMethod) {
      this.paymentMethod = paymentMethod.value;

      const currencies = this.provider.payment_methods?.[this.paymentMethod]
      if (currencies.length === 1) this.changePayCurrency({value: currencies[0]})
    },
    changePayCurrency(payCurrency) {
      this.payCurrency = payCurrency.value;
    },
    goBack() {
      this.$router.push({ name: 'providers.dashboard' });
    },
    prepareProviderObjectForRequest() {
      let fee = {}
      let limits = {}
      this.fieldsRouteFee.forEach(field=>{
        if (field.value != null && field.value != '') {
          fee[field.name] = field.value
        }
      })
      this.fieldsRouteLimits.forEach(field=>{
        if (field.value != null && field.value != '') {
          limits[field.name] = field.value
        }
      })
      const object = {
        payment_account_id: this.provider.id,
        name: this.name,
        payment_method: this.paymentMethod,
        operation_type: this.operationType,
        currencies: this.selectedCurrency,
        pay_currency: this.payCurrency,
      }
      if (!isEmpty(fee)) object.fee = fee
      if (!isEmpty(limits)) object.limits = limits
      if (this.router) object.payment_route_id = this.router.id
      return object;
    },
    handleSubmit() {
      let isCurrecyValid = this.$refs.CurrenciesList.isValid();
      this.validateForm().then(() => {
        if (!isCurrecyValid) return false;
        const object = this.prepareProviderObjectForRequest();
        if (this.router) {
          this.apiUpdateRoute(object).then(() => {
            showAppNotification("success", "Route updated successfully");
          });
        } else {
          this.apiCreateRoute(object).then(() => {
            showAppNotification("success", "Route successfully created");
          });
        }
      });
    },
    handleDeleteProvider() {
      console.log('delete');
    },
    changeSelectedCurrency(selectedCurrency) {
      this.selectedCurrency = selectedCurrency;
    },
    setRouterFields() {
      this.name = this.router.name
      this.operationType = this.router.operation_type
      this.payCurrency = this.router.pay_currency
      this.paymentMethod = this.router.payment_method
      this.selectedCurrency = this.router.currencies
      this.fieldsRouteFee.forEach(field=>{
        field.value = `${this.router.fee[field.name]}`
      })
      this.fieldsRouteLimits.forEach(field=>{
        field.value = `${this.router.limits[field.name]}`
      })
    }
  },
  mounted() {
    this.apiGetUserProviders()
  },
};
</script>

<style lang="scss">
@import './CreateAndUpdateProvider.scss';
</style>
